import { Plus } from "lucide-react";

export default function CategoriesList() {
  return (
    <div className="w-full bg-white flex flex-col items-start">
      <div className="w-full flex items-center justify-between px-6 py-4">
        <h5 className="text-15">Category List</h5>
        <button className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20 flex items-center gap-2">
          <Plus className="inline-block size-4" />
          Add Category
        </button>
      </div>

      <div className="w-full grid grid-cols-1 lg:grid-cols-4 items-start gap-5 p-6">
        <div className="w-full h-40 bg-blue-200 rounded-md p-4">
          <p className="uppercase text-xl font-bold text-blue-700">
            square one
          </p>
        </div>

        <div className="w-full h-40 bg-orange-200 rounded-md p-4">
          <p className="uppercase text-xl font-bold text-orange-700">
            tell me more
          </p>
        </div>

        <div className="w-full h-40 bg-green-200 rounded-md p-4">
          <p className="uppercase text-xl font-bold text-green-700">
            bible answers
          </p>
        </div>

        <div className="w-full h-40 bg-yellow-200 rounded-md p-4">
          <p className="uppercase text-xl font-bold text-yellow-700">
            ready to change
          </p>
        </div>
      </div>
    </div>
  );
}
