import { Plus } from "lucide-react";

export default function ArticlesList() {
  return (
    <div className="w-full bg-white flex flex-col items-start">
      <div className="w-full flex items-center justify-between px-6 py-4">
        <h5 className="text-15">Article List</h5>
        <button className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20 flex items-center gap-2">
          <Plus className="inline-block size-4" />
          Add Article
        </button>
      </div>
    </div>
  );
}
