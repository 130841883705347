import TableContainer from "Common/TableContainer";
import { Plus, Search } from "lucide-react";
import Select from "react-select";

export default function TopicList() {
  const categoryOptions = [
    { value: "Select Category", label: "Select Category" },
    { value: "square-one", label: "Square One" },
    { value: "tell-me-more", label: "Tell Me More" },
    { value: "bible-answers", label: "Bible Answers" },
    { value: "ready-to-change", label: "Ready To Change" },
  ];

  const topics: string[] = [];
  return (
    <div className="w-full bg-white flex flex-col items-start">
      <div className="w-full flex items-center justify-between px-6 py-4">
        <h5 className="text-15">Topic List</h5>
        <button className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20 flex items-center gap-2">
          <Plus className="inline-block size-4" />
          Add Topic
        </button>
      </div>

      <div className="w-full flex items-center gap-6 border-y border-dashed px-6 py-4">
        <div className="relative xl:col-span-2">
          <input
            type="search"
            className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
            placeholder="Search for a topic..."
            autoComplete="off"
          // onChange={(e) => filterSearchData(e)}
          />
          <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
        </div>
        <div className="xl:col-span-2">
          <Select
            className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
            options={categoryOptions}
            isSearchable={false}
            defaultValue={categoryOptions[0]}
            // onChange={(event) => handleChange(event)}
            id="categories-list"
          />
        </div>
      </div>

      <div className="w-full flex items-center justify-center p-6">
        {topics && topics.length > 0 ? (
          <TableContainer
            isPagination={true}
            columns={[]}
            data={[]}
            customPageSize={10}
            divclassName="-mx-5 -mb-5 overflow-x-auto"
            tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
            theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-custom-500 [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
            thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold"
            tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5"
            PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
            hiddenColumns={[
              "timezone",
              "person_of_faith",
              "preferred_language",
              "engagement_level",
            ]}
          />
        ) : (
          <div className="noresult">
            <div className="py-6 text-center">
              <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
              <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
              <p className="mb-0 text-slate-500 dark:text-zink-200">
                We've searched more than 199+ topics We did not find any topics
                for you search.
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
